import React, { useCallback, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// マップのサイズ
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

// 緯度・経度
const center = {
  lat: 33.5874,
  lng: 130.4167,
};

const Fukuoka: React.FC = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  // GoogleマップAPIキーの設定
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyBMy6zosEsQATPPuAVZeEfV9QLWaPI_UBM';

  const handleMapLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);

  return (
    <div className={`access-fukuoka hidden ${mapLoaded ? 'fade-in-up' : ''}`}>
      <span style={{ fontSize: '2rem', marginRight: '10px' }}>博多サテライトオフィス</span>
      <span style={{ fontSize: '0.8rem', verticalAlign: 'middle', color: 'red' }}>HAKATA</span>
      <div className="card-map-container">
        <LoadScript googleMapsApiKey={apiKey} onLoad={handleMapLoad}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={15}
            onLoad={handleMapLoad}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </div>
      {/* 所在地・公共交通機関でお越しの方 */}
      <table>
        <tbody>
          <tr>
            <th>所在地:</th>
            <td>福岡県福岡市博多区博多駅前３丁目７−３５ ハイテックビル 503号</td>
          </tr>
          <tr>
            <th>公共交通機関でお越しの方:</th>
            <td>
              <ul>
                <li>福岡県福岡市博多区博多駅より徒歩6分</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      {/* オフィス画像 */}
      <div className="image-gallery">
        <img src="/images/fukuoka1.jpg" alt="Sample 1" className="gallery-image" />
        <img src="/images/fukuoka2.jfif" alt="Sample 2" className="gallery-image" />
        <img src="/images/fukuoka3.jfif" alt="Sample 3" className="gallery-image" />
        <img src="/images/fukuoka4.jfif" alt="Sample 4" className="gallery-image" />
      </div>
    </div>
  );
};

export default Fukuoka;
