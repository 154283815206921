import React from 'react';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from '@syncfusion/ej2-react-layouts';
import AchievementCard1 from './AchievementCard1';
import './Achievement.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Achievement: React.FC = () => {
  useDocumentTitle('案件実績');
  return (
    <div className="achievement-container">
      <div className="coming-soon-message">
        <h1 className="animated-text">準備中です。</h1>
        <p className="animated-text">Coming Soon</p>
      </div>
      <DashboardLayoutComponent
        columns={15}
        cellSpacing={[5,5]}
        allowDragging={false}
        allowResizing={false}
        allowFloating={false}
        mediaQuery="max-width:700px">
        <PanelsDirective>
          <PanelDirective header = "Case #1" content={AchievementCard1} sizeX={3} sizeY={3}  row={0.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Case #2" content={AchievementCard1} sizeX={3} sizeY={3}  row={0.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Case #3" content={AchievementCard1} sizeX={3} sizeY={3}  row={0.5} col={10.0}></PanelDirective>
          <PanelDirective header = "Case #4" content={AchievementCard1} sizeX={3} sizeY={3}  row={4.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Case #5" content={AchievementCard1} sizeX={3} sizeY={3}  row={4.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Case #6" content={AchievementCard1} sizeX={3} sizeY={3}  row={4.5} col={10.0}></PanelDirective>
          <PanelDirective header = "Case #7" content={AchievementCard1} sizeX={3} sizeY={3}  row={8.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Case #8" content={AchievementCard1} sizeX={3} sizeY={3}  row={8.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Case #9" content={AchievementCard1} sizeX={3} sizeY={3}  row={8.5} col={10.0}></PanelDirective>
          <PanelDirective  header = "Case #10" content={AchievementCard1} sizeX={3} sizeY={3}  row={12.5} col={2.0}></PanelDirective>
          <PanelDirective  header = "Case #11" content={AchievementCard1} sizeX={3} sizeY={3}  row={12.5} col={6.0}></PanelDirective>
          <PanelDirective  header = "Case #12" content={AchievementCard1} sizeX={3} sizeY={3}  row={12.5} col={10.0}></PanelDirective>
        </PanelsDirective>
      </DashboardLayoutComponent>
      </div>
  );
};

export default Achievement;
