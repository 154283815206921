import React from 'react';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from '@syncfusion/ej2-react-layouts';
import ServiceCard1 from './InHouseServiceCard1';
import './InHouseService.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const InHouseService: React.FC = () => {
  useDocumentTitle('自社サービス');
  return (
    <div className="service-container">
      <div className="coming-soon-message">
        <h1 className="animated-text">準備中です。</h1>
        <p className="animated-text">Coming Soon</p>
      </div>
      <DashboardLayoutComponent
        columns={15}
        cellSpacing={[5,5]}
        allowDragging={false}
        allowResizing={false}
        allowFloating={false}
        mediaQuery="max-width:700px">
        <PanelsDirective>
          <PanelDirective header = "Service #1" content={ServiceCard1} sizeX={3} sizeY={3}  row={0.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Service #2" content={ServiceCard1} sizeX={3} sizeY={3}  row={0.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Service #3" content={ServiceCard1} sizeX={3} sizeY={3}  row={0.5} col={10.0}></PanelDirective>
          <PanelDirective header = "Service #4" content={ServiceCard1} sizeX={3} sizeY={3}  row={4.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Service #5" content={ServiceCard1} sizeX={3} sizeY={3}  row={4.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Service #6" content={ServiceCard1} sizeX={3} sizeY={3}  row={4.5} col={10.0}></PanelDirective>
          <PanelDirective header = "Service #7" content={ServiceCard1} sizeX={3} sizeY={3}  row={8.5} col={2.0}></PanelDirective>
          <PanelDirective header = "Service #8" content={ServiceCard1} sizeX={3} sizeY={3}  row={8.5} col={6.0}></PanelDirective>
          <PanelDirective header = "Service #9" content={ServiceCard1} sizeX={3} sizeY={3}  row={8.5} col={10.0}></PanelDirective>
        </PanelsDirective>
      </DashboardLayoutComponent>
    </div>
  );
};

export default InHouseService;
