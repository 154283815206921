import React, { useEffect, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faLaptopCode, faServer, faRobot } from '@fortawesome/free-solid-svg-icons';
import './Home.css'; 

type UpdateItem = {
  id: number;
  date: string;
  content: string;
};

type ListViewItem = UpdateItem & {
  className: string;
};

const Home: React.FC = () => {
  useDocumentTitle('ホーム');
  const navigate = useNavigate();

  const items = [
    <img src="/images/slide1.png" className="slider-image" alt="pc" />,
    <img src="/images/slide3.png" className="slider-image" alt="thankyou10year" />,
    <img src="/images/slide4.png" className="slider-image" alt="withAi" />,
  ];

  const items2 = [
    <div className="slider-item-wrapper" onClick={() => navigate('/achievement')}>
      <img src="/images/coming-soon.png" alt="sample1" className="slider-image2" />
      <div className="info-box">
        <h3 className="info-title">準備中です</h3>
        <h4 className="info-subtitle">Coming Soon</h4>
      </div>
    </div>,
    <div className="slider-item-wrapper" onClick={() => navigate('/achievement')}>
      <img src="/images/coming-soon.png" alt="sample2" className="slider-image2" />
      <div className="info-box">
        <h3 className="info-title">準備中です</h3>
        <h4 className="info-subtitle">Coming Soon</h4>
      </div>
    </div>,
    <div className="slider-item-wrapper" onClick={() => navigate('/achievement')}>
      <img src="/images/coming-soon.png" alt="sample3" className="slider-image2" />
      <div className="info-box">
        <h3 className="info-title">準備中です</h3>
        <h4 className="info-subtitle">Coming Soon</h4>
      </div>
    </div>,
  ];

  const fadeInElementsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const elementsToAnimate = fadeInElementsRef.current;

    const observerOptions = {
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    }, observerOptions);

    elementsToAnimate.forEach(element => {
      observer.observe(element);
    });
    return () => observer.disconnect();
  }, []);

  const handleItemClick = (path: string) => {
    navigate(path);
  };

  const renderTemplate = (item: ListViewItem) => (
    <div className={`home-list-item-card ${item.className}`}>
      {(item.id === 1 || item.id === 2) ? (
        <div
          className="clickable-item"
          onClick={() => handleItemClick(item.id === 1 ? '/hp-renewal-announcement' : '/aws-partner-announcement')}
        >
          <p className="item-date">{item.date}</p>
          <p className="item-content">{item.content}</p>
          {/* 矢印 */}
          <div className="arrow-circle">
            <span className="arrow-icon">→</span>
          </div>
        </div>
      ) : (
        <>
          <p className="item-date">{item.date}</p>
          <p className="item-content">{item.content}</p>
        </>
      )}
    </div>
  );

  return (
    <div className="home-container">
      {/* 画像*/}
      <div className="image-slider1">
        <AliceCarousel
          items={items}
          responsive={{
            0: { items: 1 },
            768: { items: 1 },
          }}
          autoPlay
          autoPlayInterval={5000}
          infinite
        />
      </div>
      {/* トピック */}
      <div
        className="home-listview-container fade-in-up"
        ref={(el) => el && fadeInElementsRef.current.push(el)}
      >
        <h2>TOPIC</h2>
        <h3>トピック</h3>
        <ListViewComponent
          dataSource={[
            { id: 1, date: '2024年9月30日', content: 'ホームページをリニューアルしました。', className: 'item-1' },
            { id: 2, date: '2023年10月11日', content: '「AWSセレクトティアサービスパートナー」の認定を取得しました。', className: 'item-2' },
            { id: 3, date: '2022年11月1日', content: '博多サテライトオフィス開設に伴いアクセスを更新しました。', className: 'item-3' },
            { id: 4, date: '2021年9月27日', content: '会社移転に伴いアクセスを更新しました。', className: 'item-3' },
            { id: 5, date: '2019年8月8日', content: '会社移転に伴いアクセスを更新しました。', className: 'item-4' },
            { id: 6, date: '2017年7月12日', content: '会社案内を更新しました。', className: 'item-5' },
            { id: 7, date: '2014年10月14日', content: 'サイトオープン致しました。', className: 'item-6' },
          ]}
          template={renderTemplate}
        />
      </div>
      {/* 会社案内｜業務案内｜ 採用案内 */}
      <div className="three-squares-container">
        <div className="square square-left">
          <div className="content-wrapper">
            <h3 className="square-title">｜会社案内｜</h3>
            <div className="content-right">
              <img src="/images/home1.jpg" alt="会社案内" className="square-image" />
              <p className="square-description">
                会社の概要、歴史、アクセスについて詳しく知ることができます。
              </p>
              <button onClick={() => navigate('/company')}  className="read-more-btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="square square-center">
          <div className="content-wrapper">
            <h3 className="square-title">｜業務案内｜</h3>
            <div className="content-right">
              <img src="/images/home2.png" alt="業務案内" className="square-image" />
              <p className="square-description">
                私たちの提供するサービスや業務内容についてご紹介します。
              </p>
              <button onClick={() => navigate('/business')}  className="read-more-btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="square square-right">
          <div className="content-wrapper">
            <h3 className="square-title">｜採用案内｜</h3>
            <div className="content-right">
              <img src="/images/home3.png" alt="採用案内" className="square-image" />
              <p className="square-description">
                新卒・中途採用情報や採用までの流れついて詳しく説明しています。
              </p>
              <button onClick={() => navigate('/recruitment')} className="read-more-btn">Read More</button>
            </div>
          </div>
        </div>
      </div>
      {/* 導入事例 */}
      <div className="image2-wrapper fade-in-up" ref={(el) => el && fadeInElementsRef.current.push(el)}>
        <h2>CASE STUDY</h2>
        <h3>導入事例</h3>
          <div className="image-slider2">
            <AliceCarousel
              items={items2}
              responsive={{
                0: { items: 1 },
                768: { items: 1 },
              }}
              autoPlay
              autoPlayInterval={5000}
              infinite
            />
          </div>
          <div className="button-container">
            <button onClick={() => navigate('/achievement')}>View More</button>
          </div>
      </div>
      {/* 自社サービス */}
      <div className="services-wrapper">
            <h2>OUR SERVICES</h2>
            <h3>自社サービス</h3>
            <div className="services-container">
                <div className="service-item" ref={(el) => el && fadeInElementsRef.current.push(el)}>
                    <FontAwesomeIcon icon={faLaptopCode} size="2x" />
                    <h3>システム開発</h3>
                    <p>お客様のビジネスニーズに合わせたカスタムソフトウェアを開発します。</p>
                </div>
                <div className="service-item" ref={(el) => el && fadeInElementsRef.current.push(el)}>
                    <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
                    <h3>クラウドサービス</h3>
                    <p>AWS セレクトティアサービスパートナーとして、安全で信頼性の高いクラウド環境を提供し、ビジネスの効率化をサポートします。</p>
                </div>
                <div className="service-item" ref={(el) => el && fadeInElementsRef.current.push(el)}>
                    <FontAwesomeIcon icon={faServer} size="2x" />
                    <h3>サーバー管理</h3>
                    <p>将来的な状況の変化を考慮し、理想的な環境のままで使用していただけるリソース・インフラ・ハードウェア構成を提案します。</p>
                </div>
                <div className="service-item" ref={(el) => el && fadeInElementsRef.current.push(el)}>
                    <FontAwesomeIcon icon={faRobot} size="2x" />
                    <h3>生成AI</h3>
                    <p>最新のAI技術を駆使し、業務の効率化を実現するソリューションを提供します。</p>
                </div>
            </div>
            <div className="button-container">
            <button onClick={() => navigate('/service')}>View More</button>
        </div>
        </div>
      {/* お問い合わせ */}
      <div className="contact-wrapper fade-in-up" ref={(el) => el && fadeInElementsRef.current.push(el)}>
        <h2>CONTACT</h2>
        <div className="contact-box">
          <h3>お問い合わせ</h3>
          <p>
            <i className="fas fa-phone" style={{ color: '#1e90ff' }}></i> <a href="tel:+81526845742">+81) 052-684-5742</a>
          </p>
          <p>
            <i className="fas fa-envelope" style={{ color: '#28a745' }}></i> <a href="mailto:itplus@i-t-plus.co.jp">itplus@i-t-plus.co.jp</a>
          </p>
          <p>お問い合わせはこちらから</p>
          <button onClick={() => navigate('/contact')}>View More</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
