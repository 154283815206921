import React, { useCallback, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// マップのサイズ
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

// 緯度・経度
const center = {
  lat: 35.173,
  lng: 136.896,
};

const Nagoya: React.FC = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  // GoogleマップAPIキーの設定
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyBMy6zosEsQATPPuAVZeEfV9QLWaPI_UBM';

  const handleMapLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);

  return (
    <div className={`access-nagoya hidden ${mapLoaded ? 'fade-in-up' : ''}`}>
      <span style={{ fontSize: '2rem', marginRight: '10px' }}>名古屋本社</span>
      <span style={{ fontSize: '0.8rem', verticalAlign: 'middle', color: 'red' }}>NAGOYA</span>
      <div className="card-map-container">
        <LoadScript googleMapsApiKey={apiKey} onLoad={handleMapLoad}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={15}
            onLoad={handleMapLoad}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </div>
      {/* 所在地・公共交通機関でお越しの方 */}
      <table>
        <tbody>
          <tr>
            <th>所在地:</th>
            <td>名古屋市中区丸の内一丁目17番19号 キリックス丸の内ビル705号</td>
          </tr>
          <tr>
            <th>公共交通機関でお越しの方:</th>
            <td>
              <ul>
                <li>名古屋市営地下鉄 鶴舞線 丸の内駅 7番出口より徒歩1分</li>
                <li>名古屋市営地下鉄 桜通線 丸の内駅 3番出口より徒歩2分</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      {/* オフィス画像 */}
      <div className="image-gallery">
        <img src="/images/nagoya1.jpg" alt="Sample 1" className="gallery-image" />
        <img src="/images/nagoya2.jfif" alt="Sample 2" className="gallery-image" />
        <img src="/images/nagoya3.jpg" alt="Sample 3" className="gallery-image" />
        <img src="/images/nagoya4.jpg" alt="Sample 4" className="gallery-image" />
      </div>
    </div>
  );
};

export default Nagoya;
