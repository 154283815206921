import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const location = useLocation();

  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);

    if (!isNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const handleLinkClick = () => {
    setIsNavOpen(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = '';
    };
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setIsHeaderVisible(false); // スクロールダウン = ヘッダー隠す
      } else {
        setIsHeaderVisible(true); // スクロールアップ = ヘッダー表示
      }

      // スクロール位置を更新
      setLastScrollY(currentScrollY);
    };

    // スクロール
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <header style={{ 
      transform: isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)', 
      transition: 'transform 0.3s ease',
      position: 'fixed',
      width: '100%',
      zIndex: 10000,
      height: isNavOpen ? '100vh' : 'auto' // ヘッダーが開いてるとき
    }}>
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src={'/images/hd_logo.png'} alt="ITplus Logo" />
          </Link>
          <div className="logo-text">
            <Link to="/" className="logo-link">
              <h1>株式会社 IT plus</h1>
            </Link>
            <span className="logo-subtext">ITplus Co., Ltd.</span>
          </div>
        </div>
        <nav className={`nav-menu ${isNavOpen ? 'open' : ''} header-nav`}>
          <ul>
            <li>
              <NavLink 
                exact 
                to="/" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                ホーム <span className="nav-subtext">(Home)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                exact 
                to="/greeting" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                代表挨拶 <span className="nav-subtext">(Greeting)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/company" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                会社案内 <span className="nav-subtext">(Company Info)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/business" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                業務案内 <span className="nav-subtext">(Business)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/achievement" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                案件実績 <span className="nav-subtext">(Achievements)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/service" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                自社サービス <span className="nav-subtext">(Our Services)</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/recruitment" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                採用情報 <span className="nav-subtext">(Recruitment)</span>
              </NavLink>
            </li>

            <li className="contact-link">
              <NavLink 
                to="/contact" 
                className={({ isActive }) => isActive ? "active-link" : undefined}
                onClick={handleLinkClick}
              >
                <i className="fas fa-envelope"></i> お問い合わせ <span className="nav-subtext">(Contact)</span>
              </NavLink>
            </li>
          </ul>
        </nav>
        {/*スマホ対応*/}
        <div className="nav-toggle" onClick={toggleNav}>
          {isNavOpen ? '×' : '☰'}
        </div>
      </div>
    </header>
  );
};

export default Header;
