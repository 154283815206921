import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const AchievementCard1: React.FC = () => {
  return (
    <div className='achievement-card1'>
      <div className='icon-container'>
        <FontAwesomeIcon icon={faCog} size='3x' color='#B4B4B8' />
      </div>
      <div className='message'>
        <p>準備中です</p>
      </div>
    </div>
  );
};

export default AchievementCard1;
