import React, { useEffect } from 'react';
import './Company.css';
import Card1 from './Nagoya';
import Card2 from './Fukuoka';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page } from '@syncfusion/ej2-react-grids';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from '@syncfusion/ej2-react-layouts';
import { MapsComponent, LayersDirective, LayerDirective, MapsTooltip, MarkersDirective, MarkerDirective, Marker, Legend, Zoom } from '@syncfusion/ej2-react-maps';
import { japanMap } from './japanMap';

const Company: React.FC = () => {
  useDocumentTitle('会社案内');

  const historyData = [
    { text: '平成26年5月1日 - 会社設立' },
    { text: '令和1年8月8日 - 会社移転' },
    { text: '令和3年9月27日 - 会社移転' },
    { text: '令和4年11月1日 - 博多サテライトオフィス設立' },
    { text: '令和5年10月11日 - AWS セレクトティアサービスパートナー 認定' }
  ];

  const companyData = [
    { key: '社名', value: '株式会社IT plus' },
    { key: '設立', value: '平成26年5月1日' },
    { key: '所在地', value: '本　社：〒460-0002\n名古屋市中区丸の内一丁目17番19号\nキリックス丸の内ビル705号\nTEL（052）684-5742\nFAX（052）684-5743' },
    { key: '資本金', value: '5,000,000円' },
    { key: '社員数', value: '28人' },
    { key: '代表取締役', value: '岡田　達也' },
    { key: '取引銀行', value: '名古屋銀行、東春信用金庫、瀬戸信用金庫' },
    { key: '福利厚生', value: '各種保険：健康保険、厚生年金保険、雇用保険\n各種手当：超過手当、通勤手当\n健康診断：年一回実施' },
  ];

  useEffect(() => {
    const animatedElements = document.querySelectorAll('.hidden');

    const animateOnScroll = () => {
      animatedElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top <= (window.innerHeight || document.documentElement.clientHeight)) {
          element.classList.add('fade-in-up');
        }
      });
    };
    animateOnScroll();
    window.addEventListener('scroll', animateOnScroll);
    return () => window.removeEventListener('scroll', animateOnScroll);
  }, []);

  return (
    <div className="company-page">
      <section className="company-info hidden">
        <h2>会社案内</h2>

        <div className="company-history hidden">
          <h3>沿革</h3>
          <ListViewComponent
            dataSource={historyData}
            fields={{ text: 'text' }}
            cssClass="history-list"
          />
        </div>

        <div className="company-overview hidden">
          <h3>会社概要</h3>
          <ListViewComponent
            dataSource={companyData}
            template='<div class="key-value"><span class="key">${key}</span>: <span class="value">${value}</span></div>'
            cssClass="overview-list"
          />
        </div>
      </section>

      <div className="access-container hidden">
        <h2>アクセス</h2>
        {/* マップ */}
        <div className="map-container hidden">
          <p>拠点一覧</p>
          <MapsComponent 
            centerPosition={{ latitude: 36.2048, longitude: 138.2529 }}
            legendSettings={{ visible: true }}
            zoomSettings={{ enable: false, toolbarSettings: { horizontalAlignment: 'Near' }, shouldZoomInitially: false }}
          >
            <Inject services={[Marker, Legend, MapsTooltip, Zoom]} />
            <LayersDirective>
              <LayerDirective 
                shapeData={japanMap}
                shapeSettings={{
                  fill: '#67C6E3',
                  border: { color: '#B1AFFF', width: 1 },
                  opacity: 1.0,
                }}
              >
                <MarkersDirective>
                  <MarkerDirective visible={true} shape="Circle" height={15} width={15} fill="#4CCD99"
                    dataSource={[
                      { latitude: 35.1702, longitude: 136.8833, name: 'Nagoya' },
                    ]}
                  />
                  <MarkerDirective visible={true} shape="Circle" height={15} width={15} fill="#5356FF"
                    dataSource={[
                      { latitude: 33.5902, longitude: 130.4200, info: 'Fukuoka' }
                    ]}
                  />
                  <MarkerDirective visible={true}
                    height={0}
                    width={0}
                    animationDelay={0}
                    template='<div id="marker2">名古屋</div>'
                    tooltipSettings={{
                      visible: true,
                      valuePath: 'info'
                    }}
                    dataSource={[
                      { latitude: 35.1702, longitude: 136.8833, info: '名古屋本社(Nagoya Headquarters)' },
                    ]}
                  />
                  <MarkerDirective visible={true}
                    height={20}
                    width={20}
                    animationDuration={0}
                    template='<div id="marker2">博多</div>'
                    tooltipSettings={{
                      visible: true,
                      valuePath: 'info'
                    }}
                    dataSource={[
                      { latitude: 33.5902, longitude: 130.4200, info: '博多サテライトオフィス(Hakata Satellite Office)' }
                    ]}
                  />
                </MarkersDirective>
              </LayerDirective>
            </LayersDirective>
          </MapsComponent>
        </div>

        {/* ダッシュボード */}
        <DashboardLayoutComponent
          columns={16}
          cellSpacing={[10, 10]}
          allowDragging={false}
          allowResizing={false}
          allowFloating={false}
          mediaQuery="max-width:700px"
          className="dashboard-container hidden"
        >
          <PanelsDirective>
            <PanelDirective 
              content={Card1} 
              sizeX={15} 
              sizeY={18} 
              row={0.5} 
              col={3.5} 
            />
            <PanelDirective 
              content={Card2} 
              sizeX={15} 
              sizeY={18} 
              row={19.5} 
              col={3.5} 
            />
          </PanelsDirective>
        </DashboardLayoutComponent>
      </div>
    </div>
  );
};

export default Company;
