import * as React from 'react';
import { useState } from 'react';
import './Test.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const cardsData = [
  {
    imgSrc: './images/kobai.png',
    title: '購買申請',
    category: '内製化・開発支援',
    businessType: '電気保安',
    os: 'Windows',
    content: '発注から支払までの購買に関する一連の業務を電子化し、効率化を図るシステムです。承認ワークフローから注文書発行まで一連の流れがシステムで管理されるため、各段階での進捗状況がリアルタイムで把握可能です。また、購買履歴を蓄積し、分析することで、より戦略的な購買活動が可能となります。',
    link: 'https://reactjs.org/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  },
  {
    imgSrc: './images/coming-soon2.png',
    title: '案件タイトル',
    category: 'カテゴリ',
    businessType: '業種・分野',
    os: '対応OS',
    content: '準備中です。',
    link: 'https://www.i-t-plus.co.jp/'
  }
];

const Achievement = () => {
  useDocumentTitle('案件実績');
  const [revealedCardIndex, setRevealedCardIndex] = useState<number | null>(null);

  const reveal = (index: number) => {
    setRevealedCardIndex(index);
  };
  
  const collapse = () => {
    setRevealedCardIndex(null);
  };

  return (
    <div className="control-pane">
      <div className='achievement-header'>
        <h2>案件実績</h2>
      </div>
      <div className="control-section">
        {cardsData.map((card, index) => (
          <div key={index} className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className={`e-card-resize-container ${revealedCardIndex === index ? 'show-card' : ''}`}>
              <div className="e-card">
                <img className="img-responsive" src={card.imgSrc} alt="Card Image" />
                
                {/* 表*/}
                <div id="card_revealed">
                  <div className="e-card-content">
                    <table>
                      <tbody>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>案件名</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.title}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>カテゴリ</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.category}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>業種・分野</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.businessType}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>対応OS</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.os}</div></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <button id="showcarddata" onClick={() => reveal(index)} className="e-btn e-outline e-primary">
                              Know More
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* 裏 */}
                <div id="card_reveal">
                  <div className="e-card-header">
                    <div className="e-card-header-caption">
                      <div className="e-card-header-title">{card.title}</div>
                    </div>
                    <div id="card-reveal_collapse" onClick={collapse}>
                      <span className="e-icons e-collapse"></span>
                    </div>
                  </div>
                  <div className="e-card-content">
                    {card.content}
                  </div>
                  <div className="e-card-actions">
                    <a href={card.link} target="_blank" rel="noopener noreferrer">
                      Play Demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievement;